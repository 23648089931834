import * as React from "react";
import { graphql } from "gatsby";
import { inject } from "mobx-react";

import {Seo }from "components";
import { SquidexSimplePage } from 'generated/gatsbyApiTypes';
import { getLanguageValue, TraumwandPageComponent, PAGE_VIEW_EVENT } from 'utils';
// import Img from "gatsby-image";

export interface QueryData
{
  squidexSimplePage: SquidexSimplePage;
}

@inject("visitorStore")
export default class SimplePage extends TraumwandPageComponent<QueryData> {
  componentWillMount() {
    this.props.visitorStore.logEvent(PAGE_VIEW_EVENT, {
      location: this.props.location,
      pageType: "simplepage"
    });
  }
  render() {
    const post = this.props.data.squidexSimplePage.text;
    const html = post ? getLanguageValue(post).html : undefined;
    return (
      <>
        <Seo subTitle={getLanguageValue(this.props.data.squidexSimplePage.title)} />
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </>
    )
  }
}

export const query = graphql`
query($id: String!) {
  squidexSimplePage(id: { eq: $id }) {
    title {
      de
      nl
      en
    }
    text {
      de {
        html
      }
      nl {
        html
      }
      en {
        html
      }
    }
  }
}
`
